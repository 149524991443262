<!--积分商城-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <div>
                    <label>场景：</label>
                    <el-select v-model="select.college" placeholder="选择商品分类" clearable class="selectWidth" @change="curr=1;getList()">
                        <el-option v-for="item in selectData['商品分类']" :label="item.name" :value="item.name" :key="item.id"></el-option>
                    </el-select>
                </div>
                <div>
                    <el-button type="primary" block size="small" @click="edit()">添加积分商品</el-button>
                    <el-button-group style="margin:0 5px;">
                        <el-button type="success" size="small" @click="setupSwitch(1)" icon="el-icon-top">上架</el-button>
                        <el-button type="success" size="small" @click="setupSwitch(2)" icon="el-icon-bottom">下架</el-button>
                    </el-button-group>
                    <el-button type="warning" block size="small">导出</el-button>
                </div>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' @selection-change="handleSelectionChange" height="calc( 100% - 41px )">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="title" label="商品名称" align="center" fixed show-overflow-tooltip></el-table-column>
                <el-table-column label="商品图片" prop="" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="scope.row.url" :preview-src-list="previewList"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="积分价格" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="库存" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="销量" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="拟定销量" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="排序" align="center" width="120">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.price" style="width:100px" controls-position="right" @change="sortHandleChange" :min="1" size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="state_name" label="上架" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state_id" :active-value="1"  :inactive-value="2" @change="setupSwitch(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot="header">
                        <el-input placeholder="搜索商品名称" v-model="select.searchValue" size="small" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
                        </el-input>
                    </template>
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="edit(scope.row,'show')">查看</el-button>
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!--活动设置-->
        <el-dialog title="活动设置" :visible.sync="dialogFormVisible" :modal-append-to-body="false"  :close-on-click-modal="false" width="600px">
            <el-form ref="formAuth"  :model="formData"  size="small" label-position="top" class="alertForm">
                <el-form-item label="" prop="cate_name" style="width:100%">
                    <el-checkbox v-model="formData.seckill">设置秒杀</el-checkbox>
                    <el-date-picker v-model="formData.seckillTime" style="width:calc(100% - 100px);margin-left:10px" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" prop="cate_name" style="width:100%">
                    <el-checkbox v-model="formData.seckill">设置拼团</el-checkbox>
                    <el-date-picker v-model="formData.seckillTime" style="width:calc(100% - 100px);margin-left:10px" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" prop="cate_name" style="width:100%">
                    <el-checkbox v-model="formData.tuijian">精品推荐</el-checkbox>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    // import {shopListApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[],//下拉选择列表
                list:[{
                    id:1,
                    title:'红酒',
                    price:100,
                    url:'https://img2.baidu.com/it/u=2620306848,1106594030&fm=26&fmt=auto&gp=0.jpg',
                    state_id:1
                    
                },{
                    id:2,
                    title:'白酒',
                    price:100,
                    url:'https://img2.baidu.com/it/u=2620306848,1106594030&fm=26&fmt=auto&gp=0.jpg',
                    state_id:1
                    
                }], //商品列表
                formData:{},
                previewList:[], //图片预览列表

                title:'添加商品',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogFormVisible:false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function(){
                this.selectData['商品分类'] = [{
                    id:1,
                    name:'红酒'
                }];
                this.list.forEach(item => {
                    this.previewList.push(item.url);
                });
            },
            //商品列表
            getList: function(){

            },
            /**
             * 活动设置
            */
            setupActive: function(){
                this.dialogFormVisible = true;
            },
            //编辑商品
            edit: function(param = {},_state){
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加积分商品';
                    this.isShow = false;
                }else if(typeof param.id && _state){
                    this.title = '积分商品详情';
                    this.isShow = true;
                }else {
                    this.title = '编辑积分商品';
                    this.isShow = false;
                }
                //跳转至添加or编辑页面
                this.$router.push({path:'/integralStoreAdd',query:{id:param.id,title:this.title,isShow:JSON.stringify(this.isShow)}});
            },
            //删除商品
            deletes:function(param){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    console.log(param)
                    // var url = '/admin/goods/delete';
                    // this.http.post(url, {
                    //     id: row.id,
                    // }).then(() => {
                    //     this.getList();
                    //     this.$message({
                    //         type: 'success',
                    //         message: '已删除!'
                    //     });
                    // })
                }).catch(()=>{

                })
            },
            //设置上架
            setupSwitch: function(row) {
                console.log(row)
            },
            //批量设置上架
            handleSelectionChange: function(val) {
                console.log(val)
            },
            sortHandleChange: function(currentValue, oldValue){
                console.log(currentValue)
                console.log(oldValue)
            },

            //活动设置提交
            submit: function(){

            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>